import error from '@client/assets/icons/error.svg';
import { Provider } from '@arc-connect/schema';
import { Button, FlexCrate, Text } from '@arcadiapower/shrike';
import { useTranslation } from 'react-i18next';
import { ContentSection, ContentSectionFullHeight, Footer } from '../connect-container';
import { Status } from './mfa-unsupported-screen.style';

export type Props = {
  provider: Provider;
  onProviderReselect?: () => void;
};

export const MFAUnsupportedScreen = ({ provider, onProviderReselect }: Props): JSX.Element => {
  const { t } = useTranslation('connect', { keyPrefix: 'components.mfaUnsupportedScreen' });
  return (
    <ContentSectionFullHeight>
      <ContentSection centerContent>
        <FlexCrate flexDirection="column" alignItems="center">
          <Status src={error} />
          <Text>
            {t('description.firstLine', {
              providerName: provider.name,
            })}
          </Text>
          <Text margin="10px 0 0 0">
            {t('description.secondLine')}
            <span>{onProviderReselect ? t('description.reselectProvider') : ''}</span>
          </Text>
        </FlexCrate>
      </ContentSection>
      {onProviderReselect ? (
        <Footer>
          <Button
            type="button"
            fullWidth={true}
            mode="primary"
            aria-label={t('selectButton')}
            onClick={onProviderReselect}
          >
            {t('selectButton')}
          </Button>
        </Footer>
      ) : null}
    </ContentSectionFullHeight>
  );
};

import { useForm, useController } from 'react-hook-form';
import { useCallback } from 'react';
import { Alert, Text, Radio, Button, Link } from '@arcadiapower/shrike';
import { useTranslation } from 'react-i18next';
import { Form } from '@client/components/form';
import { ContentSectionFullHeight, Footer } from '@client/components/connect-container';
import { Provider, MfaVerificationMethods, MfaVerificationMethod } from '@schema/schemas';
import { parseErrorMessage } from '@client/config/errors';

export type Props = {
  provider: Provider;
  mfaVerificationMethods: MfaVerificationMethods;
  onSubmit: ({
    selectedVerificationMethod,
  }: {
    selectedVerificationMethod: MfaVerificationMethod;
  }) => void;
  onProviderReselect?: () => void;
  pending: boolean;
  error?: unknown;
};

export const MfaVerificationMethodForm = ({
  provider,
  mfaVerificationMethods,
  onSubmit,
  onProviderReselect,
  pending,
  error,
}: Props): JSX.Element => {
  const { t } = useTranslation('connect', { keyPrefix: 'components.mfaVerificationMethodForm' });

  const { control, handleSubmit } = useForm({
    defaultValues: { mfaVerificationMethodIdentifier: '' },
  });
  const { field, fieldState } = useController({
    name: 'mfaVerificationMethodIdentifier',
    control,
    rules: {
      required: t('validations.verificationMethodRequired'),
    },
  });

  const handleFormSubmit = useCallback(
    ({ mfaVerificationMethodIdentifier }: { mfaVerificationMethodIdentifier: string }) => {
      const selectedVerificationMethod = mfaVerificationMethods.find(
        method => method.identifier === mfaVerificationMethodIdentifier
      );
      if (selectedVerificationMethod) onSubmit({ selectedVerificationMethod });
    },
    [onSubmit, mfaVerificationMethods]
  );

  return (
    <>
      <ContentSectionFullHeight dataAttributes={{ testid: 'mfa-verification-method-form' }}>
        <Text margin={{ bottom: '32px' }} opacity="medium">
          {t('description', { providerName: provider.name })}
        </Text>
        <Text margin={{ bottom: '12px' }} tag="h2" textStyle="heading700">
          {t('methodTitle')}
        </Text>
        <Form
          onSubmit={handleSubmit(data => handleFormSubmit(data))}
          id="mfaVerificationMethodForm"
        >
          {mfaVerificationMethods.map(method => {
            const { name, identifier, type } = method;
            return (
              <Radio
                name={field.name}
                key={identifier}
                checked={field?.value === identifier}
                onChange={field.onChange}
                margin={{ bottom: '12px' }}
                value={identifier}
              >
                <Text textStyle="paragraph400">
                  {t(`methodType.${type}`, { methodName: name })}
                </Text>
              </Radio>
            );
          })}
          <Text margin={{ bottom: '32px' }} opacity="medium" textStyle="paragraph400">
            {t('disclaimer')}
          </Text>
          {!!onProviderReselect && (
            <>
              <Text textStyle="paragraph400">{t('unableToAuthenticate')}</Text>
              <Text textStyle="paragraph400">
                <Link color="blue" onClick={onProviderReselect} openInNewTab={false} type="inline">
                  {t('selectUtility')}
                </Link>
              </Text>
            </>
          )}
          {!!(error || fieldState.error?.message) && (
            <Alert>{parseErrorMessage(error, fieldState.error?.message)}</Alert>
          )}
        </Form>
      </ContentSectionFullHeight>
      <Footer>
        <Button
          form="mfaVerificationMethodForm"
          aria-label={t('submitButton')}
          type="submit"
          loading={pending}
          fullWidth={true}
        >
          {t('submitButton')}
        </Button>
      </Footer>
    </>
  );
};

import { Provider, MfaVerificationMethod } from '@arc-connect/schema';
import { Alert, Button, FlexCrate, Input, Link, Text } from '@arcadiapower/shrike';
import { useMemo } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContentSectionFullHeight, Footer } from '@client/components/connect-container';
import { Form } from '@client/components/form';
import { parseErrorMessage } from '@client/config/errors';

export type Props = {
  selectedVerificationMethod?: MfaVerificationMethod;
  onSubmit: ({ verificationCode }: { verificationCode: string }) => void;
  onMethodReselect: () => void;
  onResendCode: () => void;
  provider: Provider;
  pending: boolean;
  submissionError?: unknown;
  isResubmission: boolean;
};

export const MfaVerificationCodeForm = ({
  selectedVerificationMethod,
  onSubmit,
  onMethodReselect,
  onResendCode,
  provider,
  pending,
  submissionError,
  isResubmission,
}: Props): JSX.Element => {
  const { t } = useTranslation('connect', { keyPrefix: 'components.mfaVerificationCodeForm' });
  const { control, handleSubmit } = useForm({ defaultValues: { verificationCode: '' } });
  const { field, fieldState } = useController({
    name: 'verificationCode',
    control,
    rules: {
      required: t('validations.fieldRequiredErrorMessage', {
        fieldName: t('inputs.verificationCode'),
      }),
    },
  });

  const getResubmissionError = () => {
    return isResubmission ? t('errors.invalidCode') : undefined;
  };
  const description = useMemo(() => {
    const typeKey = selectedVerificationMethod?.type ?? 'other';
    return t(`description.${typeKey}`, {
      providerName: provider.name,
      verificationMethod: selectedVerificationMethod?.name,
    });
  }, [t, selectedVerificationMethod, provider]);

  return (
    <>
      <ContentSectionFullHeight dataAttributes={{ testid: 'mfa-verification-code-form' }}>
        <Text margin={{ bottom: '32px' }}>{description}</Text>
        <Form onSubmit={handleSubmit(data => onSubmit(data))} id="mfaVerificationCodeForm">
          <FlexCrate gap="16px" flexDirection="column">
            <Input
              label={t('inputs.verificationCode')}
              name={field.name}
              value={field.value}
              onBlur={field.onBlur}
              onChange={field.onChange}
              errorText={
                fieldState?.error?.message ? fieldState.error.message : getResubmissionError()
              }
            />
            {!!submissionError && <Alert>{parseErrorMessage(submissionError)}</Alert>}
            <Text textStyle="paragraph400">
              {t('resendCode.description')}
              <Text textStyle="paragraph400">
                <Link color="blue" onClick={onResendCode} openInNewTab={false} type="inline">
                  {t('resendCode.link')}
                </Link>
              </Text>
            </Text>
            <Text textStyle="paragraph400">
              {t('reselectMethod.description')}
              <Text textStyle="paragraph400">
                <Link color="blue" onClick={onMethodReselect} openInNewTab={false} type="inline">
                  {t('reselectMethod.link')}
                </Link>
              </Text>
            </Text>
          </FlexCrate>
        </Form>
      </ContentSectionFullHeight>
      <Footer>
        <Button
          type="submit"
          loading={pending}
          fullWidth={true}
          form="mfaVerificationCodeForm"
          aria-label={t('inputs.submitButton')}
        >
          {t('inputs.submitButton')}
        </Button>
      </Footer>
    </>
  );
};
